import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc, getDoc, doc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB31zHEuKGZaiIqw3Ww5TZDobGfuliOPsg",
  authDomain: "matchstats-se.firebaseapp.com",
  projectId: "matchstats-se",
  storageBucket: "matchstats-se.appspot.com",
  messagingSenderId: "413163204040",
  appId: "1:413163204040:web:a7ed27d4e8b9ddb76ba4fd",
  measurementId: "G-26EGKWQDNY",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const db = getFirestore();
const googleProvider = new GoogleAuthProvider();
const saveUserData = async ({ uid, name, email, authProvider }) => {
  console.log("save user");
  await setDoc(doc(db, "users", uid), {
    name,
    email,
    authProvider,
  });
};
const signInWithGoogle = () => {
  signInWithPopup(auth, googleProvider)
    .then((result) => {
      console.log("logged in", result);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      //const token = credential.accessToken;
      console.log(credential);
      // The signed-in user info.
      const user = result.user;
      const docRef = doc(db, "users", user.uid);
      const docSnap = getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
      } else {
        saveUserData({
          uid: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
        });
        console.log("No such document!");
      }

      //console.log("Document written with ID: ", docRef.id);
      //}
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};
const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  createUserWithEmailAndPassword(auth, email, password).then(
    (userCredential) => {
      // Signed in
      const user = userCredential.user;
      saveUserData({
        uid: user.uid,
        name: name,
        authProvider: "local",
        email: user.email,
      });
    }
  );
};
const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  auth.signOut();
};
export {
  auth,
  db,
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
};

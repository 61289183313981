import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { auth, db, logout } from "../firebase";
import Button from "@mui/material/Button";
function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");
  }, [user, loading]);
  console.log(user);
  return (
    <>
      <Button variant="contained" onClick={logout}>
        Logout
      </Button>
      <div>{user.displayName}</div>
    </>
  );
}
export default Dashboard;

import React from "react";
import "./App.css";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Signup from "./components/Signup";
function App() {
  const theme = createTheme();
  return (
    <Router>
      <Switch>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Route exact path="/" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/signup" component={Signup} />
          </Container>
        </ThemeProvider>
      </Switch>
    </Router>
  );
}

export default App;
